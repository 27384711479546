@use 'src/src/app/scss/abstracts' as *;

$aligns: ('start', 'center', 'inherit', 'initial', 'unset', 'end', 'justify', 'left', 'right');

$weights: (
  'm': $font-weight-m,
  'l': $font-weight-l,
  'xl': $font-weight-xl,
);

$sizes: (
  'xs': (
    small: (
      font-size: $font-size-xs,
      line-height: $font-line-s,
      font-weight: $font-weight-m,
      letter-spacing: -0.32px,
    ),
    large: (
      font-size: $font-size-s,
      line-height: $font-line-s,
      font-weight: $font-weight-m,
      letter-spacing: -0.54px,
    ),
  ),
  's': (
    small: (
      font-size: $font-size-s,
      line-height: $font-line-l,
      font-weight: $font-weight-m,
      letter-spacing: -0.32px,
    ),
    large: (
      font-size: $font-size-m,
      line-height: $font-line-m,
      font-weight: $font-weight-m,
      letter-spacing: -0.54px,
    ),
  ),
  '2s': (
    small: (
      font-size: $font-size-2s,
      line-height: $font-line-m,
      font-weight: $font-weight-m,
      letter-spacing: -0.32px,
    ),
    large: (
      font-size: $font-size-2s,
      line-height: $font-line-m,
      font-weight: $font-weight-m,
      letter-spacing: -0.54px,
    ),
  ),
  '3s': (
    small: (
      font-size: $font-size-m,
      line-height: $font-line-m,
      font-weight: $font-weight-m,
      letter-spacing: -0.32px,
    ),
    large: (
      font-size: $font-size-m,
      line-height: $font-line-m,
      font-weight: $font-weight-m,
      letter-spacing: -0.54px,
    ),
  ),
  'm': (
    small: (
      font-size: $font-size-s,
      line-height: $font-line-l,
      font-weight: $font-weight-m,
      letter-spacing: -0.32px,
    ),
    large: (
      font-size: $font-size-xl,
      line-height: $font-line-l,
      font-weight: $font-weight-m,
      letter-spacing: -0.48px,
    ),
  ),
  'l': (
    small: (
      font-size: $font-size-l,
      line-height: $font-line-xl,
      font-weight: $font-weight-m,
      letter-spacing: -0.63px,
    ),
    large: (
      font-size: $font-size-3xl,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -1.08px,
    ),
  ),
  'xl': (
    small: (
      font-size: $font-size-xl,
      line-height: $font-line-l,
      font-weight: $font-weight-l,
      letter-spacing: -1.2px,
    ),
    large: (
      font-size: $font-size-4xl,
      line-height: $font-line-s,
      font-weight: $font-weight-xl,
      letter-spacing: -0.96px,
    ),
  ),
);

@mixin fs($small, $large) {
  font-size: map-get($small, font-size);
  line-height: map-get($small, line-height);
  letter-spacing: map-get($small, letter-spacing);
  font-weight: map-get($small, font-weight);

  @include mq(md) {
    font-size: map-get($large, font-size);
    line-height: map-get($large, line-height);
    letter-spacing: map-get($large, letter-spacing);
    font-weight: map-get($large, font-weight);
  }
}

@mixin ellipsis-rows($small, $large, $lines: 2) {
  @include ellipsis-multiline(
    $font-size: map-get($small, font-size),
    $line-height: map-get($small, line-height),
    $lines-to-show: $lines
  );

  @include mq(md) {
    @include ellipsis-multiline(
      $font-size: map-get($large, font-size),
      $line-height: map-get($large, line-height),
      $lines-to-show: $lines
    );
  }
}

.text {
  $block-name: &;

  color: $color-semantic-third;
  font-family: $font-family-main;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: $font-weight-l;

  a {
    color: $color-semantic-secondary;
    transition: color $transition-fast;
    text-decoration: none;

    &:hover {
      color: $color-base-purple-600;
    }

    &:active {
      color: $color-semantic-secondary;
    }
  }

  @each $key, $size in $sizes {
    &--size-#{$key} {
      @include fs(map-get($size, small), map-get($size, large));

      &#{$block-name} {
        &--ellipsis-rows-two {
          @include ellipsis-rows(map-get($size, small), map-get($size, large), $lines: 2);
        }

        &--ellipsis-rows-three {
          @include ellipsis-rows(map-get($size, small), map-get($size, large), $lines: 3);
        }
      }
    }
  }

  @each $align in $aligns {
    &--align-#{$align} {
      text-align: unquote($align);
    }
  }

  @each $key, $weight in $weights {
    &--weight-#{$key} {
      font-weight: $weight;
    }
  }

  &--color-main {
    color: $color-semantic-main;
  }

  &--color-inherit {
    color: inherit;
  }

  &--ellipsis-height-prop-height {
  }

  &--ellipsis-height-prop-max-height {
    height: auto !important;
  }
}
